import { usePageDataSelector } from '@/globals/dataProviders/usePageDataSelector';
import { List } from 'immutable';

import { config } from 'moduleAlias/config';
import { isFilled } from '@dop/shared/helpers/immutableHelpers';
import { MainRowLayout } from '@dop/shared/components/pageLayout/MainRowLayout';
import { Box } from '@dop/ui-primitives/planeDivision/Box';
import { deprecatedFlexLayoutCss } from '@dop/shared/components/pageLayout/deprecatedFlexLayoutCss';
import { getIn } from '@dop/shared/helpers/immutableHelpersWithArgs';
import { CrsWrapper } from 'moduleAlias/project/src/components/crsWrapper';

import PromoBlock from './promoBlock';

export const useRelatedContent = () => {
	const blocks = usePageDataSelector(getIn(['blocks'], List()));
	const providerType = usePageDataSelector(getIn(['providerType']));

	return {
		hasRelatedContent: isFilled(blocks),
		blocks,
		providerType,
	};
};

export const RelatedContent = () => {
	const { hasRelatedContent, blocks, providerType } = useRelatedContent();

	if (!hasRelatedContent) return null;

	if (providerType === config.providerTypes.homepage) return null;

	return (
		<CrsWrapper>
			<MainRowLayout
				mainSlot={
					<Box css={deprecatedFlexLayoutCss}>
						{blocks.map((item, key) => (
							<PromoBlock item={item} key={key} />
						))}
					</Box>
				}
			/>
		</CrsWrapper>
	);
};
